


export default function TermOfUse() {
  return (
    <div className="App">
      <header className="App-header">
        <h3>Welcome on cohive website</h3>
        <h1>This web site is still a work to do.</h1>
        <p>Scroll down to see the terms of use.</p>
      </header>
      <section>
        <h1>Conditions d'utilisation pour l'Application Cohive</h1>

        <p><strong>Date d'effet :</strong> 04/02/2024</p>

        <p>Merci d'avoir choisi Cohive ("l'Application"), fournie par Cohive, la société ("nous," "notre," ou "nos"). En utilisant l'Application, vous acceptez de vous conformer et d'être lié par les présentes Conditions d'utilisation. Veuillez lire attentivement ces termes avant d'utiliser l'Application.</p>
      </section>

      <section>
        <h1>Terms of Use for Cohive Application</h1>

        <p><strong>Effective Date:</strong> 02/04/2024</p>

        <p>Thank you for choosing Cohive ("the Application"), provided by Cohive, the company ("we," "us," or "our"). By using the Application, you agree to comply with and be bound by the following Terms of Use. Please read these terms carefully before using the Application.</p>
      </section>

      <ol>
        <li>
          <h3>Data Storage and Location:</h3>
          <p>a. The Cohive Application stores data on servers located in Germany.</p>
          <p>b. We do not transfer your data to any other country.</p>
        </li>

        <li>
          <h3>Data Usage:</h3>
          <p>a. We do not sell your data to third parties.</p>
          <p>b. We do not read, update, or delete your data.</p>
          <p>c. We will delete data after the test sesssion.</p>
        </li>

        {/* <!-- Repeat similar structure for other sections --> */}

      </ol>

      {/* <!-- Repeat similar structure for other sections --> */}

      <p>By using the Cohive Application, you agree to these Terms of Use. If you do not agree with these terms, please do not use the Cohive Application.</p>

      <footer>
        <p><strong>Contact Information:</strong> For any questions or concerns regarding these Terms of Use, please contact us at <a href="mailto:jeremy.trips@gmail.com">jeremy.trips@gmail.com</a>.</p>
      </footer>

    </div>
  )
}