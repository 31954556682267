import React, { useState } from 'react';
import firestore, { addDoc, collection, getFirestore, setDoc } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: "AIzaSyDEGrH85cKPrizyJYkXnCbjq9kvrE8JnrE",
  authDomain: "cohive-b72ea.firebaseapp.com",
  databaseURL: "https://cohive-b72ea-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "cohive-b72ea",
  storageBucket: "cohive-b72ea.appspot.com",
  messagingSenderId: "821361625553",
  appId: "1:821361625553:web:0b9fd1abb30aecfc08f8f0"
};

export const firestoreApp = initializeApp(firebaseConfig);

const db = getFirestore(firestoreApp);

export default function Review() {
  const [review, setReview] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      // Get Firestore reference
      setIsLoading(true);
      // Add review to Firebase
      await addDoc(collection(db, 'reviews'),
        {
          review,
          timestamp: new Date().toISOString()
        }
      );
      setIsLoading(false);
      setIsSubmitted(true);

      // Redirect to home or any other page
    } catch (error) {
      console.error('Error submitting review:', error);
      setIsLoading(false);
    }
  };

  return (
    <div style={{ backgroundColor: '#282c34', height: '100vh', width: "100vw", margin: '0 auto', display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
      <div style={{ flex: 1, backgroundColor: "#29292bd9", borderRadius: '25px', paddingRight: "50px", paddingLeft: "50px" }}>
        <h3 style={{ color: 'white' }}>Welcome on cohive website</h3>
        <p style={{ color: 'white' }}>This web site is still a work to do.</p>
      </div>
      {
        isSubmitted && (
          <div style={{ backgroundColor: '#4caf50', padding: '10px', textAlign: 'center' }}>
            Merci pour votre aide!
          </div>
        )
      }
      <div
        style={{
          maxWidth: '400px',
          display: 'flex',
          justifyContent: 'center',
          flex: 10,
          alignItems: 'center'
        }}
      >
        <form onSubmit={handleSubmit}>
          <h3 style={{ color: 'white' }}>Des retours sur l'application cohive?</h3>

          <label style={{ display: 'block', marginBottom: '10px' }}>
            <p style={{ color: 'white' }}>N'hésitez pas à les écrire ci dessous</p>
            <textarea
              value={review}
              onChange={(e) => setReview(e.target.value)}
              style={{ width: '100%', padding: '10px', marginBottom: '10px', border: '1px solid #ddd', borderRadius: '4px', height: '150px' }}
              required
            />
          </label>
          <button type="submit" style={{ backgroundColor: '#61dafb', color: 'white', padding: '10px', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>
            {isLoading ? 'Envois en cours...' : 'Envoyer'}
          </button>
        </form>
      </div>

    </div >
  );
};
