import { addDoc, collection, getFirestore } from 'firebase/firestore';
import { z } from 'zod';
import { firestoreApp } from '../deleteData';

const schema = z.object({
    name: z.string({
        invalid_type_error: 'Invalid name',
    }),
    email: z.string({
        invalid_type_error: 'Invalid Email',
    }),
    consent: z.boolean({
        invalid_type_error: 'Invalid Consent',
    }),
})

interface FormData {
    name: string;
    email: string;
    consent: boolean;
}
const firestore = getFirestore(firestoreApp);  


export async function createContactForm(
    formData: FormData
) {
    const validatedFields = schema.safeParse(formData)
    if (!validatedFields.success) {
        return {
            consent: false,
            email: '',
            name: '',
        } as FormData;
        // } validatedFields.error.flatten().fieldErrors;
    }
    const contactCollection  = collection(firestore, 'contactForm');
    await addDoc(contactCollection, validatedFields.data);
}