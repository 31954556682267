


export const Logo = () => {
    return (
        <img
            src={process.env.PUBLIC_URL+"/files/logo_long.svg"}
            alt="logo"
            width={100}
            height={100}
        />
    )
} 