import { useState } from 'react';
import style from "./contact.module.css";
import { createContactForm } from '../actions/contactForm';

interface FormData {
    name: string;
    email: string;
    consent: boolean;
}

type stringify<T> = {
    [key in keyof T]: string;
};


export default function ContactForm() {
    const [formData, setFormData] = useState<FormData>({
        name: '',
        email: '',
        consent: false
    });
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState<Partial<stringify<FormData>>>({});
    const [submitted, setSubmitted] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        const val = type === 'checkbox' ? checked : value;
        setFormData({ ...formData, [name]: val });

        if (name === 'name') {
            setErrors((prevErrors) => ({ ...prevErrors, name: value.trim() ? '' : 'Name is required' }));
        } else if (name === 'email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            setErrors((prevErrors) => ({ ...prevErrors, email: emailRegex.test(value) ? '' : 'Invalid email address' }));
        } else if (name === 'consent') {
            setErrors((prevErrors) => ({ ...prevErrors, consent: !checked ? 'You must accept the terms and conditions' : '' }));
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const formErrors: Partial<stringify<FormData>> = {};
        if (!formData.name.trim()) {
            formErrors.name = 'Name is required';
        }
        if (!formData.email.trim()) {
            formErrors.email = 'Email is required';
        } else {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(formData.email)) {
                formErrors.email = 'Invalid email address';
            }
        }
        if (!formData.consent) {
            formErrors.consent = 'You must accept the terms and conditions';
        }
        setErrors(formErrors);

        if (Object.values(formErrors).some(error => error)) {
            return;
        }

        setSubmitting(true);

        try {
            // Submit the form data
            await createContactForm(formData);
            // Clear the form fields
            setFormData({ name: '', email: '', consent: false });
            setSubmitted(true);
        } catch (error) {

        } finally {
            setSubmitting(false);
        }
    };

    return (
        <div className={style.container}>
            <div className={style.formContainer}>
                <p className={`${style.header} ${style.bold}`}>{"Let's keep in touch"}</p>
                <div>
                    <p className={``}>
                        Get notified of the latest news and updates. <br />You will receive an email as soon as the application is available.
                    </p>
                </div>
                {submitted ? ( // Display thank you message if form submitted
                    <p className={style.thankYouMessage}>Thank you for your submission!</p>
                ) : (

                    <form
                        onSubmit={handleSubmit}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px'
                        }}
                    >
                        <div className={style.column}>
                            <label className={`${style.bold} `} htmlFor="name">Name</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder={"Enter your name"}
                                className={style.input}
                            />
                            {errors.name && <p className={`${style.error} `} style={{ color: 'red' }}>{errors.name}</p>}
                        </div>
                        <div className={style.column}>
                            <label className={`${style.bold} `} htmlFor="email">Email</label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder={"Enter your email"}
                                className={style.input}
                            />
                            {errors.email && <p className={`${style.error} `} style={{ color: 'red' }}>{errors.email}</p>}
                        </div>
                        <div>
                            <label
                                style={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}
                            >
                                <input
                                    type="checkbox"
                                    id="consent"
                                    name="consent"
                                    checked={formData.consent}
                                    onChange={handleChange}
                                    style={{
                                        backgroundColor: "red",
                                        marginRight: "4px"
                                    }}
                                />
                                <p className={''}>I accept the terms and conditions</p>
                            </label>
                            {errors.consent && <p className={`${style.error}`} style={{ color: 'red' }}>{errors.consent}</p>}
                        </div >
                        <button
                            type="submit"
                            disabled={submitting}
                            style={{
                                height: '54px',
                                backgroundColor: '#252525',
                                flexGrow: 0,
                                flexShrink: 1,
                                width: "fit-content",
                                alignSelf: "flex-end",
                                borderRadius: 16,
                                position: 'relative' // Add this style for relative positioning
                            }}
                        >
                            {submitting && ( // Show spinner if submitting is true
                                <div className={style.spinner} /> // Add your spinner component or animation here
                            )}
                            <p className={""} style={{
                                color: 'white',
                                margin: "16px 24px",
                            }}>Submit</p>
                        </button>
                    </form >
                )}
            </div>
        </div >
    );
}
