import React, { useState, useEffect } from 'react';
import styles from './navbar.module.css';
import { Logo } from './logo';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu: React.MouseEventHandler<HTMLDivElement> = (e) => {
        e.stopPropagation();
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    const closeMobileMenu = () => {
        setMobileMenuOpen(false);
    };

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (isMobileMenuOpen && event!==null && event.target !== null) {
                closeMobileMenu();
            }
        };
        document.addEventListener('click', handleOutsideClick);
        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [isMobileMenuOpen]);

    return (
        <nav className={styles.navbar}>
            <div className={styles.logo}>
                <Link to="/" style={{}}>
                    <Logo /> 
                </Link>
            </div>
            <ul className={`${styles.navLinks} ${isMobileMenuOpen ? styles.open : ''}`}>
                <li className={styles.contact}>
                    <Link to="/contact" onClick={closeMobileMenu}>
                            <p className={styles.contat}>Contact us</p>
                    </Link>
                </li>
                {/* Add more navigation links as needed */}
            </ul>
            <div className={styles.mobileMenuIcon} style={{textAlign: "center"}} onClick={toggleMobileMenu}>
                <img src={process.env.PUBLIC_URL+"/files/navi_icon.svg"} alt='hamburger' width={40} height={40} color='#FF856A' />
            </div>
        </nav>
    );
};

export default Navbar;
