import React from 'react';

import styles from "./home.module.css"
import Line from "./line.svg"
import { Link } from 'react-router-dom';

export default function Home() {
    // Placeholder translations function

    return (
        <div className={styles.container}>
            <div className={`${styles.item} ${styles.a}`}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start'
                    }}
                >
                    <p style={{
                        // font-extrabold text-4xl,
                        fontWeight: 'bolder',
                        fontSize: '2.25rem',
                        lineHeight: '2.5rem',
                        marginBottom: '0rem',
                    }}>
                        Optimize and organize your flatshare's life<br />
                    </p>
                    <img
                        src={process.env.PUBLIC_URL+"/files/spaghetti.svg"}
                        alt="spaghetti"
                        width={70}
                        height={36}
                        style={{
                            position: "relative",
                        }}
                    />
                </div>
                <p style={{textAlign: "justify"}}>
                    Build lasting relationships with your roommates with the <span style={{fontWeight: "bold"}}>#1</span> 
                    <span style={{
                            backgroundImage: Line,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "150% auto",
                            backgroundPosition: "center bottom"
                        
                    }} className={styles.colocationSpan}> flatshare</span> management app! 
                    <br/>
                    <br/>
                    Cohive is your all in one management tool for shared living. Manage your finances, documents, daily tasks and shared errands Cohive also helps you find the roommate who really suits you, thanks to filters, targeted conversations and profiles specific to shared living               
            </p>
            <p></p>
            </div>
            <div className={`${styles.item} ${styles.b} ${styles.centered}`}>
                <img src={process.env.PUBLIC_URL+"/files/image1.png"} alt="Missing pixel image" style={{ display: 'inline-block', marginLeft: '5px' }} height={"400px"} />
            </div>
            <div className={`${styles.item} ${styles.c}`}>
                <Link to="/contact">
                <div className={styles.contactButton}>
                    <p style={{textDecoration: "none"}}>
                        Stay informed
                        <img src={process.env.PUBLIC_URL+"/files/arrow.svg"} alt="missing-arrow" width={16} height={16} style={{ display: 'inline-block', marginLeft: '5px' }} />
                    </p>
                </div>
                </Link>
            </div>
            <div className={`${styles.item} ${styles.d}`}>
                <p style={{textAlign: "center"}}>
                    <img src={process.env.PUBLIC_URL+"/files/arrowRight.svg"} alt="arrow-right" width={16} height={16} style={{ display: 'inline-block', marginRight: '5px' }} />
                    Soon available on <span className='font-bold'>google play</span> and <span className='font-bold'>App store</span>
                </p>
            </div>
        </div>
    )
}
